import { useSpring, animated } from '@react-spring/web'
import { routeMap } from '/routeMap'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { ButtonLinkWhite } from '/features/buildingBlocks/Button'
import { HeadingSlantedExtraLargeRight } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { easeInOutQuart } from '/machinery/motion'

import styles from './Hero.css'

export function Hero({ title, intro, imageSm, imageLg, button }) {

  const animatedClipPathLeft = useSpring({
    from: {
      '--animated-clipping-point-left-left-x': '3%',
      '--animated-clipping-point-left-left-y': '100%',
      '--animated-clipping-point-left-right-x': '36%',
      '--animated-clipping-point-left-right-y': '100%'
    },
    to: {
      '--animated-clipping-point-left-left-x': '48%',
      '--animated-clipping-point-left-left-y': '0%',
      '--animated-clipping-point-left-right-x': '81%',
      '--animated-clipping-point-left-right-y': '0%'
    },
    config: {
      easing: easeInOutQuart,
      duration: 500
    }
  })

  const animatedClipPathRight = useSpring({
    from: {
      '--animated-clipping-point-right-left-x': '41%',
      '--animated-clipping-point-right-left-y': '100%',
      '--animated-clipping-point-right-right-x': '73%',
      '--animated-clipping-point-right-right-y': '100%'
    },
    to: {
      '--animated-clipping-point-right-left-x': '68%',
      '--animated-clipping-point-right-left-y': '40%',
      '--animated-clipping-point-right-right-x': '100%',
      '--animated-clipping-point-right-right-y': '40%'
    },
    config: {
      easing: easeInOutQuart,
      duration: 500
    },
    delay: 275
  })

  const animationClipPaths = { ...animatedClipPathLeft, ...animatedClipPathRight }

  return (
    <div className={styles.component}>
      <div className={styles.layout}>
        <div className={styles.content}>
          {title && <HeadingSlantedExtraLargeRight h={1} {...{ title }} />}
          <TextAndButton layoutClassName={styles.desktopText} {...{ intro, button }} />
        </div>
        {imageLg?.asset && (
          <animated.div
            style={animationClipPaths}
            className={cx(styles.image, styles.imageMd)}
          >
            <ImageCover
              aspectRatio={4 / 3}
              image={imageLg}
              layoutClassName={styles.coverImage}
              sizes='calc((95vw - 48px - 225px) / 2 + 225px)'
            />
          </animated.div>
        )}
        {imageSm?.asset && (
          <div className={cx(styles.image, styles.imageSm)}>
            <ImageCover
              aspectRatio={4 / 4}
              image={imageSm}
              layoutClassName={styles.coverImage}
              sizes='calc((95vw - 90px) / 2 + 90px)'
            />
          </div>
        )}
      </div>
      <TextAndButton layoutClassName={styles.mobileText} {...{ intro, button }} />
    </div>
  )
}

function TextAndButton({ intro, button, layoutClassName }) {
  return (
    <div className={cx(styles.componentTextAndButton, layoutClassName)}>
      {intro && (
        <p>{intro}</p>
      )}
      {button?.label && button?.ref && (
        <ButtonLinkWhite
          href={button.ref && determineDocumentPathSync({ document: button.ref, routeMap })}
          dataX='link'
          layoutClassName={styles.button}
        >
          {button.label}
        </ButtonLinkWhite>
      )}
    </div>
  )
}
